/*global gtag*/
$().ready(function(){
	setupSlideMenu();
	testimonialMover();
	$(".infield").inFieldLabels();
	$("form").validate();
	scaleLocation();
	mobileTestimonial();
	$("a.full-view, div.popup").click(function(e){
		e.preventDefault();
		if ($(this).attr("href")){
			var link = $(this).attr("href");
		} else {
			link = "#"+$(this).attr("id");
		}
		$(link).toggleClass("active");
	});
	internallinks() ;
});

export function setupSlideMenu(){

	$("#mobile-menu, .slide-menu").click(function(){
		$("body").toggleClass("slide-menu-active");
	});

	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});

	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
	slidePos();
}
export function slidePos() {
	var scroll = $(window).scrollTop();
	if(scroll > 0) {
		$("#mobile-menu").addClass("not-top");
	} else {
		$("#mobile-menu").removeClass("not-top");
	}
}
window.onscroll = function() {
	slidePos();
};

export function testimonialMover() {
	var slider = $(".testimonial-slider");
	var testimonial = $(".testimonial");
	var testIndex = 0;
	if (testimonial.length == 1) {
		testimonial.css({"left":"50%", "transform":"translateX(-50%)"});
	} else if (testimonial.length == 2) {
		var fadeInt = 0;
		testimonial.css({"left":"50%", "transform":"translateX(-50%)","opacity": 0});
		var setFadeClasses = function setFadeClasses() {
			testimonial.eq((testIndex - 1) % testimonial.length).addClass("fade-off").removeClass("fade-on");
			testimonial.eq((testIndex) % testimonial.length).addClass("fade-on").removeClass("fade-off");
			testimonial.eq((testIndex + 1) % testimonial.length).addClass("fade-off").removeClass("fade-on");
			testIndex++;
		};
		setFadeClasses();
		if(fadeInt) {
			clearInterval(fadeInt);
		}
		fadeInt = setInterval(function() {setFadeClasses();},5000);
		testimonial.hover(function() {
			clearInterval(fadeInt);
		}, function() {
			fadeInt = setInterval(function() {setFadeClasses();},5000);
		});
	} else {
		startSlider();
	}
	slider.hover(function(){slider.stop();}, startSlider);
	function startSlider(){
		var testWidth = testimonial.width();		
		var timething = (testWidth-slider.scrollLeft()) / testWidth;
		timething = timething * 10000;
		slider.animate({scrollLeft: testWidth}, timething, "linear", reset);
	}
	function reset(){
		slider.scrollLeft(0);
		slider.children().first().appendTo(slider);
		startSlider();
	}
}

export function scaleLocation() {
	var area = $(".where-location");
	$(".where-button").on("click", function(){
		$(this).toggleClass("active");
		area.toggleClass("active");

	});
}
export function mobileTestimonial() {
	$(".mob-test").on("click", function(){
		var thingy = $(this);
		var thingies = $(".mob-test");
		var height = thingy.find(".mob-test-cont");
		height = height.height();
		if(thingy.height() > 50) {
			thingy.css({"height": 50});
			thingies.css({"height": 50});
		} else {
			thingies.css({"height": 50});
			thingy.css({"height": height});
		}
	});
}
export function internallinks() {
	$("a[href*=\"#\"]").on("click", function(){
		var url = $(this).attr("href");
		var title = $(this).text();
		if (typeof(gtag) !== "undefined") {
			gtag("event", "page_view", {"page_location": url, "page_title": title+"-ajax"});
		}
	});
}